//Breakpoints
$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

//Singularity
$unit: 16px;

.full-vh {
  // width: 100%;
  height: calc(var(--vh, 1vh) * 100);
}

.full-layout-view {
  height: calc(var(--vh, 1vh) * 100);
  min-height: calc(var(--sh, 1vh) * 100);
  // width: 100%;
}

.lp-container{
  padding: 0 $unit;
  @media (min-width: $md) {
    padding: 0 calc(4 * #{$unit});
  }
  @media (min-width: $xxl) {
    padding: 0;
  }
}

:export {
  xs: $xs;
  sm: $sm;
  md: $md;
  lg: $lg;
  xl: $xl;
  xxl: $xxl;
  unit: $unit;
}
