@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');
@import './layouts.scss';
@import './variables.module.scss';

p,
input,
textarea {
  font-family: 'Roboto', sans-serif;
  line-height: 150%;
}
h1,
h2,
h3,
.display1,
.display2,
.display3 {
  font-family: 'Roboto Condensed', sans-serif;
  line-height: 90%;
  font-weight: 700;
}

$fontSize: 1rem;

p {
  font-size: 1.125rem;
}
h1 {
  font-size: 2.688rem;
  text-transform: uppercase;
}
h2 {
  font-size: 2rem;
  text-transform: capitalize;
}
h3 {
  font-size: 1.5rem;
  color: $secondaryColor;
}
.display1 {
  font-size: 6.312rem;
}
.display2 {
  font-size: 4.75rem;
}
.display3 {
  font-size: 3.562rem;
}
.caption {
  font-size: 0.875rem;
  letter-spacing: calc(0.06 * #{$unit});
  text-transform: uppercase;
}
.small {
  font-size: 0.625rem;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
}
.btn {
  font-size: 1.125rem;
  font-weight: bold;
  letter-spacing: calc(0.03 * #{$unit});
  text-transform: uppercase;
}

.navlinks {
  font-size: 1.125rem;
  letter-spacing: 0.09rem;
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: capitalize;
}

@media (min-width: $xl) {
  p {
    font-size: 1.25rem;
  }
  h1 {
    font-size: 2.938rem;
  }
  h2 {
    font-size: 2.25rem;
  }
  h3 {
    font-size: 1.688rem;
  }
  .display1 {
    font-size: 7rem;
  }
  .display2 {
    font-size: 5.25rem;
  }
  .display3 {
    font-size: 3.938rem;
  }
  .caption {
    font-size: 0.938rem;
  }
  .small {
    font-size: 0.688rem;
    letter-spacing: 0.0688rem;
  }
  .btn {
    font-size: 1.25rem;
  }
  .navlinks {
    font-size: 1.25rem;
    letter-spacing: 0.101rem;
  }
}