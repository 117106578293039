@import '../src//styles/reset.scss';
@import '../src/styles/typography.scss';
@import '../src/styles/layouts.scss';
@import '../src/styles/colors.scss';

#root {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%;
}
.App {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

@media (min-width: $lg) {
  /* Works on Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.1);
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0);
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    border: 3px solid rgba(255, 255, 255, 0);
  }

  * ::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}

.section-intro{
  display: flex;
  flex-direction: column;
  gap: $unit;
}

.underline {
  width: calc(4 *#{$unit});
  height: calc(0.25 *#{$unit});
  background-color: $secondaryColor;
  border: 3px solid $secondaryColor;
  border-radius: 4px;
}

.overlay {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(19, 15, 51, 0.7);
}

.s-shape-up,
.s-shape-down {
  img {
    width: calc(1.618 * 100vw);
    height: auto;
    @media (orientation: landscape) and (min-width: $md) {
      width: calc(1.618 * 50vw);
    }
  }
}

[data-theme='light'] {
  .s-shape-up,
  .s-shape-down {
    img {
      opacity: 0.04;
    }
  }
}

[data-theme='dark'] {
  .s-shape-up,
  .s-shape-down {
    img {
      opacity: 0.01;
    }
  }
}