@import '../../styles/layouts.scss';
@import '../../styles/variables.module.scss';

.navbar {
  width: 100%;
  padding: $unit $unit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1201;
  .navbar-content {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .toggle-menu-icon {
      position: relative;
      background-color: transparent;
      width: auto;
      padding: 0;
      z-index: 1100;
    }
  }
}

@media (min-width: $xl) {
  .navbar {
    padding: calc(2 * #{$unit}) calc(2 * #{$unit});
  }
}

.hide-navbar {
  transform: translateY(-100%);
  transition: all 0.3s ease-in-out;
}

.bg-gradient-dark {
  // background: $primaryColorDark;
  background: linear-gradient(
    142deg,
    rgba(19, 15, 51, 1) 0%,
    rgba(25, 20, 62, 1) 33%,
    rgba(19, 15, 51, 1) 100%
  );
}

.bg-gradient-light {
  background: $backgroundLight;
}